<template>
  <div class="coupon pt-2">
    <v-text-field
      v-if="coupon.length > 0"
      class="search-input mt-4"
      label="Search"
      outlined
      single-line
      rounded
      dense
      v-model="search"
      append-icon="mdi-magnify"
    ></v-text-field>
    <div class="card-container pb-5" v-if="isAvalible">
      <div v-for="(itemCoupon, index) in coupon" :key="index">
        <div class="section-title primary--text font-500">
          {{ itemCoupon.section }}
        </div>
        <v-divider class="mt-2 mb-5"></v-divider>
        <div
          class="card-group mb-2"
          v-for="(item, index) in itemCoupon.items"
          :key="-index"
          @click="handleClick(item)"
        >
          <v-row no-gutters>
            <v-col cols="4">
              <div class="coupon-img primary-gradient--bg">
                <img
                  v-if="item.coupon.thumbnail_image_url"
                  :src="item.coupon.thumbnail_image_url"
                  width="110px"
                  height="110px"
                  alt=""
                />
                <img v-else width="82px" :src="noImage" alt="" />
              </div>
            </v-col>
            <v-col cols="8" class="coupon-info px-4 py-2">
              <v-row no-gutters>
                <v-col cols="12">
                  <div class="coupon-title primary--text text-14 font-500">
                    {{ item.coupon.name }}
                  </div>
                </v-col>
                <v-col cols="12">
                  <div
                    class="description text-12 font-400"
                    v-html="item.coupon.description"
                  ></div>
                </v-col>
                <v-col cols="12">
                  <div v-if="item.expire_date > 0" class="expired text-10">
                    หมดอายุ :
                    {{ toDate(item.expire_date) }}
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
    <div v-else class="text-center font-700 mt-10">ไม่มีคูปอง</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      noImage: require("@/assets/img/phyathai-logo-white.png"),
      search: "",
    };
  },
  props: {
    onTab: {
      type: String,
      default: "",
    },
    coupons: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    coupon() {
      if (this.coupons.length > 0) {
        const coupon = [
          ...new Set(this.coupons.map((e) => e.coupon.section)),
        ].map((s) => {
          return {
            section: s,
            items: this.coupons.filter((c) => {
              return (
                c.coupon.section === s &&
                c.coupon.name
                  .toLocaleLowerCase()
                  .includes(this.search.toLocaleLowerCase())
              );
            }),
          };
        });
        return coupon;
      } else {
        return [];
      }
    },
    isAvalible() {
      if (this.coupon.length < 1) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    handleClick(coupon) {
      this.$emit("selectCoupon", coupon);
    },
  },
};
</script>

<style lang="scss" scoped>
.card-group {
  border-radius: 10px;
  border: 1px solid var(--v-primaryLight-base) !important;
  box-shadow: 0px 4px 20px rgba(170, 170, 170, 0.5);
}
.coupon-img {
  border-radius: 8px 0px 0px 8px;
  width: 110px;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    border-radius: 8px 0px 0px 8px;
    object-fit: cover;
  }
}
.coupon-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.description {
  color: #757575;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.expired {
  color: #cecece;
}
</style>

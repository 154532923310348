<template>
  <div class="phyathai-card">
    <v-carousel
      :show-arrows="false"
      hide-delimiter-background
      height="270"
      color="#235D3A"
      class="carousel font-400 text-12"
      @change="changeDomain"
    >
      <v-carousel-item
        v-for="membership in memberships"
        :key="membership.domain"
        :value="membership.domain"
        class="card-carousel text-center pt-3"
      >
        <div class="card" @click="showUseCoupon = true">
          <div text class="privilage primary-light--bg text-14 font-500">
            ใช้สิทธิ์บัตร
          </div>
          <img class="card-img" :src="membership.image" />
        </div>
        <div class="d-flex justify-space-between mt-1">
          <div class="expired-date pl-12">
            วันหมดอายุ :
            {{ toDate(membership.expiration_date) }}
          </div>
          <div @click="renewCard" class="renew-card primary--text pr-12">
            ต่ออายุการใช้งาน
          </div>
        </div>
      </v-carousel-item>
    </v-carousel>
    <UseCoupon
      :active="showUseCoupon"
      @close="showUseCoupon = false"
      @submit="showConfirm()"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import UseCoupon from "@/components/dialog/UseCard";

export default {
  name: "PhyathaiCard",
  components: {
    UseCoupon,
  },
  data: () => ({
    showUseCoupon: false,
  }),
  computed: {
    ...mapState("Auth", ["user"]),
    ...mapState("Layout", ["onDomain"]),
    memberships() {
      return this.user.memberships.filter((e) => e.domain && !e.pending);
    },
  },
  methods: {
    showConfirm() {
      this.showUseCoupon = false;
      this.$emit("slideCard");
    },
    changeDomain(domain) {
      this.$emit("changeCard", domain);
    },
    renewCard() {
      this.$emit("renewCard");
    },
  },
};
</script>

<style lang="scss" scoped>
.card-group {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  column-gap: 0.7em;
  flex-wrap: nowrap;
  -webkit-overflow-scrolling: touch;
}
.card-carousel {
  width: 390px;
  margin: auto;
}

.card {
  position: relative;
  margin: auto;
  border-radius: 20px;
  width: 300px;
  height: 185px;
}

.card-img {
  object-fit: cover;
  border-radius: inherit;
  width: inherit;
  height: inherit;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.privilage {
  position: absolute;
  top: 20px;
  left: 0;
  border-radius: 0px 20px 20px 0px;
  width: 90px !important;
  height: 40px !important;
  color: white;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  z-index: 2;
  justify-content: center;
}

.expired-date {
  color: #969595;
}
.renew-card {
  text-decoration: underline;
}
</style>

<template>
  <v-row justify="center">
    <v-dialog v-model="active" v-if="myCoupon" persistent max-width="350">
      <v-card class="card">
        <v-card-title
          class="bg-color primary-gradient--bg d-flex justify-space-around pa-3"
        >
          <v-spacer></v-spacer>
          <v-toolbar-title class="text-20 text-center white--text ml-8"
            >ใช้คูปอง</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn class="bg-icon" icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <div class="coupon-thumbnail">
          <div class="coupon-img primary-gradient--bg">
            <img
              v-if="myCoupon.coupon.thumbnail_image_url"
              :src="myCoupon.coupon.thumbnail_image_url"
              height="185px"
              width="100%"
              alt=""
            />
            <img
              v-else
              :src="noImage"
              style="border-radius: 9px 0px 0px 9px"
              width="172px"
              alt=""
            />
          </div>
        </div>
        <div class="d-flex justify-center px-5">
          <div class="information-group">
            <div class="information">
              <div class="primary--text mt-2 text-20 font-500">
                {{ myCoupon.coupon.name }}
              </div>
              <div
                class="text-14 mt-1"
                style="color: rgba(117, 117, 117, 1)"
                v-html="myCoupon.coupon.description"
              ></div>

              <v-divider class="my-4"></v-divider>

              <div class="condition">
                <div class="condition-info mt-2">
                  <v-row
                    class="text-12"
                    style="color: rgba(150, 149, 149, 1)"
                    no-gutters
                  >
                    <v-col cols="3">ประเภท</v-col>
                    <v-col cols="1">:</v-col>
                    <v-col
                      class="text-14"
                      style="color: rgba(117, 117, 117, 1)"
                      cols="8"
                    ></v-col>
                  </v-row>
                  <v-row
                    class="text-12"
                    style="color: rgba(150, 149, 149, 1)"
                    no-gutters
                  >
                    <v-col cols="3">วันหมดอายุ</v-col>
                    <v-col cols="1">:</v-col>
                    <v-col
                      class="text-14"
                      style="color: rgba(117, 117, 117, 1)"
                      cols="8"
                      >{{ toDate(myCoupon.expire_date) }}</v-col
                    >
                  </v-row>
                  <v-row
                    class="text-12"
                    style="color: rgba(150, 149, 149, 1)"
                    no-gutters
                  >
                    <v-col cols="5">จำนวนคูปองคงเหลือ</v-col>
                    <v-col cols="1">:</v-col>
                    <v-col
                      class="text-14"
                      style="color: rgba(117, 117, 117, 1)"
                      cols="6"
                      >{{ myCoupon.usage_limit - myCoupon.usage_count }}</v-col
                    >
                  </v-row>
                </div>
              </div>
              <v-divider class="my-4"></v-divider>
              <div class="slide-group">
                <div class="primary--text text-center">
                  <div class="text-16 font-500 primary--text">
                    กรุณาเลื่อนไปทางขวาเพื่อยืนยันสิทธิ์
                  </div>
                  <div class="text-12" style="color: rgba(150, 149, 149, 1)">
                    และโปรดแสดงหน้าใช้สิทธิบัตรสมาชิกนี้ต่อเจ้าหน้าที่ เท่านั้น
                  </div>
                </div>
                <div class="mt-5 d-flex justify-center mb-9" v-if="active">
                  <drag-verify
                    :width="285"
                    :height="50"
                    text="เลื่อนไปทางขวา"
                    success-text="สำเร็จ"
                    text-size="14"
                    handlerBg="#235D3A"
                    background="#EAEAEA"
                    progressBarBg="#34343434"
                    :handlerIcon="arrowLeft"
                    completedBg="#235D3A"
                    :circle="true"
                    @passcallback="$emit('submit')"
                  ></drag-verify>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  name: "registerwithcodemodal",
  data() {
    return {
      noImage: require("@/assets/img/phyathai-logo-white.png"),
      closeicon: require("@/assets/icon/closeicon.png"),
      arrowLeft: require("@/assets/icon/arrowleft.png"),
      myCoupon: null,
    };
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    coupon: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    coupon() {
      this.fetchCoupon();
    },
  },
  methods: {
    fetchCoupon() {
      this.myCoupon = this.coupon;
      // console.log(this.myCoupon);
    },
    close() {
      this.$emit("close");
    },
    submit() {
      this.$emit("submit");
    },
  },
  created() {},
};
</script>
<style lang="scss" scoped>
.card {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(170, 170, 170, 0.5);
  border-radius: 20px;
}
.bg-color primary-gradient--bg {
  color: #ffffff;
}
.bg-icon {
  background: #fff;
}

.coupon-img {
  object-fit: cover;
  width: 100%;
  height: 185px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    object-fit: cover;
  }
}
</style>

<template>
  <v-row justify="center">
    <v-dialog v-model="active" persistent max-width="350">
      <v-card class="card">
        <v-card-title
          class="bg-color primary-gradient--bg d-flex justify-space-around pa-3"
        >
          <v-spacer></v-spacer>
          <v-toolbar-title class="text-20 text-center">
            {{ titleBar }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn class="bg-icon" icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <div class="head-top d-flex justify-space-between py-3 px-4">
          <div class="head-title primary--text">
            <div class="font-700 text-20">
              {{ onDomain === "be" ? "My Family" : "My Baby" }}
            </div>
            <div class="text-16">
              {{ onDomain === "be" ? "Be Phyathai 3" : "Mommy & Baby Club" }}
            </div>
          </div>
        </div>
        <div v-if="onDomain === 'be'" class="profile-group">
          <div
            v-for="(profile, index) in informations.members"
            :key="index"
            class="profile-info d-flex pa-5"
          >
            <div class="profile" style="width: 60px; height: 60px">
              <img
                :src="profile.general_profile.picture_url"
                class="profile-picture"
                width="60px"
                height="60px"
              />
            </div>
            <div class="info-group d-flex align-center justify-center">
              <div class="group">
                <div class="font-500 text-18 primary--text">
                  {{ profile.general_profile.first_name }}
                  {{ profile.general_profile.last_name }}
                </div>
                <div class="font-400 text-14" style="color: #757575">
                  Member ID : {{ profile.code }}
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="
              informations.host?.code === user.code &&
              informations.members.length < 3
            "
            class="plus-group profile-info d-flex pa-5"
          >
            <div style="width: 60px; height: 60px">
              <v-btn
                icon
                class="add-profile"
                style="width: 60px; height: 60px"
                @click="showInvite = true"
              >
                <img width="17px" :src="plus" />
              </v-btn>
            </div>
            <div class="info-group d-flex align-center justify-center">
              <div class="group">
                <div class="font-500 text-18 primary--text">
                  เพิ่มสมาชิกครอบครัว
                </div>
                <div class="font-400 text-14" style="color: #757575">
                  ใช้ Invite Code เพื่อเชิญสมาชิก
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="profile-group">
          <div
            v-for="(profile, index) in informations.babies"
            :key="index"
            class="profile-info d-flex pa-5"
          >
            <div class="profile" style="width: 60px; height: 60px">
              <img
                :src="profile.gender === 'male' ? babyMale : babyFemale"
                class="profile-picture"
                width="100%"
              />
            </div>
            <div class="info-group d-flex align-center justify-center">
              <div class="group">
                <div class="font-500 text-18 primary--text">
                  {{ profile.first_name }} {{ profile.last_name }}
                </div>
                <!-- <div class="font-400 text-14" style="color: #757575">
                  รหัสผู้ป่วยนอก (HN) : {{ profile.id }}
                </div> -->
              </div>
            </div>
          </div>
          <div class="plus-group profile-info d-flex pa-5">
            <div style="width: 60px; height: 60px">
              <v-btn
                icon
                class="add-profile"
                style="width: 60px; height: 60px"
                @click="showAddBaby = true"
              >
                <img width="17px" :src="plus" />
              </v-btn>
            </div>
            <div class="info-group d-flex align-center justify-center">
              <div class="group">
                <div class="font-500 text-18 primary--text">เพิ่มข้อมูลลูก</div>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <InviteFamilyModal
      :active="showInvite"
      :isInvalid="isInvalid"
      @close="showInvite = false"
      @submit="inviteFamily"
    />
    <ValidationObserver ref="babyForm">
      <AddBabyModal
        :active="showAddBaby"
        @close="showAddBaby = false"
        @submit="addBaby"
      />
    </ValidationObserver>
  </v-row>
</template>
<script>
import { mapState } from "vuex";
import InviteFamilyModal from "@/components/modal/InviteFamilyModal";
import AddBabyModal from "@/components/modal/AddBabyModal";

export default {
  name: "family-dialog",
  data() {
    return {
      closeicon: require("@/assets/icon/closeicon.png"),
      babyMale: require("@/assets/img/baby_male.svg"),
      babyFemale: require("@/assets/img/baby_female.svg"),
      plus: require("@/assets/icon/plus.png"),
      showInvite: false,
      showAddBaby: false,
      isInvalid: false,
    };
  },
  components: {
    InviteFamilyModal,
    AddBabyModal,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    informations: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    ...mapState("Auth", ["user"]),
    ...mapState("Layout", ["onDomain"]),
    ...mapState("Request", ["requestApi"]),

    titleBar() {
      if (this.onDomain === "be") {
        return "ข้อมูลสมาชิกของคุณ";
      } else {
        return "ข้อมูลบุตรของคุณ";
      }
    },
  },
  methods: {
    async addBaby(profile) {
      const isValid = await this.$refs.babyForm.validate();
      if (!isValid) return;
      try {
        this.showAddBaby = false;
        this.$wait.start("app loading");
        await this.requestApi.membershipBaby.updateMembershipBaby({
          domain: this.onDomain,
          requestBody: {
            babies: [
              ...this.informations.babies,
              {
                first_name: profile.first_name,
                last_name: profile.last_name,
                hospital_number: profile.hospital_number,
                gender: profile.gender,
              },
            ],
          },
        });
        this.$emit("fetchInformations");
      } catch (e) {
        this.showAddBaby = true;
        console.log(e);
      } finally {
        this.$wait.end("app loading");
      }
    },
    async inviteFamily(code) {
      try {
        this.showInvite = false;
        this.$wait.start("app loading");
        await this.requestApi.membershipHost.addMembershipHostMember({
          domain: this.onDomain,
          requestBody: {
            user_code: code,
          },
        });
        this.$emit("fetchInformations");
      } catch (e) {
        this.showInvite = true;
        this.isInvalid = true;
        console.log(e);
      } finally {
        this.$wait.end("app loading");
      }
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(170, 170, 170, 0.5);
  border-radius: 20px;
}
.bg-color {
  color: #ffffff;
}
.bg-icon {
  background: #ffffff;
}

.head-top {
  background: var(--v-primaryInformation-base);
  border-bottom: 1px solid var(--v-primaryLight-base);
  box-shadow: 0px 1px 10px rgba(170, 170, 170, 0.5);
}

.card-img {
  object-fit: cover;
  border-radius: 20px;
  width: 300px;
  height: 185px;
}

.profile {
  border: 0px solid var(--v-primaryLight-base);
  border-radius: 100%;
}
.profile-picture {
  border-radius: inherit;
}
.profile-info {
  column-gap: 20px;
}
.profile-info:nth-child(even) {
  background-color: #f2f2f2;
}
.profile-info:last-child {
  border-radius: 0px 0px 20px 20px;
}

.add-profile {
  display: flex;
  align-items: center;
  justify-content: center;
  border: dashed 1.3px #757575;
  border-radius: 100%;
}
</style>

<template>
  <v-row justify="center">
    <v-dialog v-model="active" persistent max-width="350">
      <v-card class="card">
        <v-card-title class="bg-color primary-gradient--bg d-flex justify-space-around pa-3">
          <v-spacer></v-spacer>
          <v-toolbar-title class="text-20 text-center"
            >ใช้สิทธิ์บัตรสมาชิก</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn class="bg-icon" icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <div class="d-flex justify-center pt-4 px-5">
          <div class="information-group">
            <div class="information">
              <img class="card-img" :src="card.image" />
              <div class="primary--text mt-1">
                <div class="text-18 font-500">ข้อมูลสมาชิกบัตร</div>
                <div class="text-20 font-700">{{ titleByDomain }}</div>
              </div>
              <div class="card-info mt-2">
                <v-row
                  class="text-12"
                  style="color: rgba(150, 149, 149, 1)"
                  no-gutters
                >
                  <v-col cols="3">รหัสสมาชิก</v-col>
                  <v-col cols="1">:</v-col>
                  <v-col
                    class="font-500 text-16"
                    style="color: rgba(117, 117, 117, 1)"
                    cols="8"
                    >{{ user.code }}</v-col
                  >
                </v-row>
                <v-row
                  class="text-12"
                  style="color: rgba(150, 149, 149, 1)"
                  no-gutters
                >
                  <v-col cols="3">ชื่อผู้ถือบัตร</v-col>
                  <v-col cols="1">:</v-col>
                  <v-col
                    class="font-500 text-16"
                    style="color: rgba(117, 117, 117, 1)"
                    cols="8"
                    >{{ user.general_profile.first_name }}
                    {{ user.general_profile.last_name }}</v-col
                  >
                </v-row>
                <v-row
                  class="text-12"
                  style="color: rgba(150, 149, 149, 1)"
                  no-gutters
                >
                  <v-col cols="3">วันที่หมดอายุ</v-col>
                  <v-col cols="1">:</v-col>
                  <v-col
                    class="font-500 text-16"
                    style="color: rgba(117, 117, 117, 1)"
                    cols="8"
                    >{{ toDate(card.expiration_date) }}</v-col
                  >
                </v-row>
              </div>
              <v-divider class="my-4"></v-divider>
              <div class="slide-group">
                <div class="primary--text text-center">
                  <div class="text-16 font-500 primary--text">
                    กรุณาเลื่อนไปทางขวาเพื่อยืนยันสิทธิ์
                  </div>
                  <div class="text-12" style="color: rgba(150, 149, 149, 1)">
                    และโปรดแสดงหน้าใช้สิทธิบัตรสมาชิกนี้ต่อเจ้าหน้าที่ เท่านั้น
                  </div>
                </div>
                <div class="mt-5 d-flex justify-center mb-9" v-if="active">
                  <drag-verify
                    :width="285"
                    :height="50"
                    text="เลื่อนไปทางขวา"
                    success-text="สำเร็จ"
                    text-size="14"
                    :handlerBg="$vuetify.theme.themes['light'].primary"
                    background="#EAEAEA"
                    progressBarBg="#34343434"
                    :handlerIcon="arrowLeft"
                    :completedBg="$vuetify.theme.themes['light'].primary"
                    :circle="true"
                    @passcallback="$emit('submit')"
                  ></drag-verify>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "registerwithcodemodal",
  data() {
    return {
      closeicon: require("@/assets/icon/closeicon.png"),
      arrowLeft: require("@/assets/icon/arrowleft.png"),
      card: null,
    };
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    onDomain() {
      this.fetchCard();
    },
  },
  computed: {
    ...mapState("Auth", ["user"]),
    ...mapState("Layout", ["onDomain"]),
    titleByDomain() {
      if (this.onDomain === "be") {
        return "Be Phyathai 3 Family";
      } else if (this.onDomain === "prestige") {
        return "Long Term Prestige";
      } else if (this.onDomain === "all-you") {
        return "All You Can Check";
      } else {
        return "Mommy & Baby Club";
      }
    },
  },
  methods: {
    fetchCard() {
      this.card = this.user.memberships.filter(
        (e) => e.domain === this.onDomain
      )[0];
    },
    close() {
      this.$emit("close");
    },
    submit() {
      this.$emit("submit");
    },
  },
  created() {
    this.fetchCard();
  },
};
</script>
<style lang="scss" scoped>
.card {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(170, 170, 170, 0.5);
  border-radius: 20px;
}
.bg-color {
  color: #ffffff;
}

.bg-icon {
  background: #fff;
}

.card-img {
  object-fit: cover;
  border-radius: 20px;
  width: 300px;
  height: 185px;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-information mb-10"},[_c('div',{staticClass:"card-container px-5"},[(_vm.showHeader)?_c('div',{staticClass:"information-header"},[_c('div',{staticClass:"header-group pt-3 px-5"},[_c('div',{staticClass:"head-top d-flex justify-space-between",staticStyle:{"color":"#235d3a"}},[_c('div',{staticClass:"head-title primary--text"},[_c('div',{staticClass:"font-700 text-20"},[_vm._v(" "+_vm._s(_vm.onDomain === "be" ? "My Family" : "My Baby")+" ")]),_c('div',{staticClass:"text-16"},[_vm._v(" "+_vm._s(_vm.onDomain === "be" ? "Be Phyathai 3" : "Mommy & Baby Club")+" ")])])]),(_vm.onDomain === 'be')?_c('div',{staticClass:"d-flex mt-1 align-end justify-space-between"},[_c('img',{attrs:{"width":"67px","height":"62px","src":_vm.familyLogo}}),_c('div',{staticClass:"profile-group d-flex align-end pb-3",on:{"click":function($event){_vm.showProfile = true}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.informations.host?.code !== _vm.user.code),expression:"informations.host?.code !== user.code"}],staticClass:"profile",staticStyle:{"width":"60px","height":"60px"}},[_c('img',{staticClass:"profile-picture",attrs:{"src":_vm.informations.host?.general_profile?.picture_url,"width":"100%"}})]),_vm._l((_vm.informations.members),function(profile,index){return _c('div',{key:index},[_c('div',{staticClass:"profile",style:(_vm.informations.host?.code !== _vm.user.code
                    ? 'width: 50px; height: 50px'
                    : 'width: 60px; height: 60px')},[_c('img',{staticClass:"profile-picture",attrs:{"src":profile.general_profile?.picture_url,"width":"100%"}})])])}),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.informations.host?.code === _vm.user.code &&
                _vm.informations?.members.length < 3
              ),expression:"\n                informations.host?.code === user.code &&\n                informations?.members.length < 3\n              "}],staticClass:"add-profile",style:(_vm.informations.host?.code !== _vm.user.code
                  ? 'width: 50px; height: 50px'
                  : 'width: 60px; height: 60px'),attrs:{"icon":""}},[_c('img',{attrs:{"width":"17px","src":_vm.plus}})])],2)]):_c('div',{staticClass:"d-flex mt-1 align-end justify-space-between"},[_c('img',{attrs:{"width":"80px","height":"65px","src":_vm.mombabyLogo}}),_c('div',{staticClass:"profile-group d-flex align-end pb-3",on:{"click":function($event){_vm.showProfile = true}}},[_vm._l((_vm.informations.babies),function(profile,index){return _c('div',{key:index},[_c('div',{staticClass:"profile",staticStyle:{"width":"60px","height":"60px"}},[_c('img',{staticClass:"profile-picture",attrs:{"src":profile.gender === 'male' ? _vm.babyMale : _vm.babyFemale,"width":"100%"}})])])}),_c('v-btn',{staticClass:"add-profile",staticStyle:{"width":"60px","height":"60px"},attrs:{"icon":""}},[_c('img',{attrs:{"width":"17px","src":_vm.plus}})])],2)])])]):_vm._e(),_c('div',{staticClass:"card-group py-8 px-5",style:([!_vm.showHeader && { 'border-radius': '20px' }])},[(_vm.consent.content)?_c('div',{staticClass:"information-detail",staticStyle:{"overflow-wrap":"break-word"},domProps:{"innerHTML":_vm._s(_vm.consent.content)}}):_c('div',{staticClass:"font-700 text-center text-16"},[_vm._v("ไม่พบเนื้อหา")])])]),(_vm.showProfile)?_c('FamilyDialog',{attrs:{"active":_vm.showProfile,"informations":_vm.informations},on:{"fetchInformations":_vm.fetchInformations,"close":function($event){_vm.showProfile = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
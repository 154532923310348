<template>
  <v-row justify="center">
    <v-dialog v-model="active" v-if="coupon" persistent max-width="350">
      <v-card class="card pb-1">
        <v-card-title
          class="bg-color primary-gradient--bg d-flex justify-space-around pa-3"
        >
          <v-spacer></v-spacer>
          <v-toolbar-title class="text-20 text-center white--text ml-8"
            >รายละเอียดคูปอง</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn class="bg-icon" icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <div class="coupon-thumbnail">
          <div class="coupon-img primary-gradient--bg">
            <img
              v-if="coupon.thumbnail_image_url"
              :src="coupon.thumbnail_image_url"
              height="185px"
              width="100%"
              alt=""
            />
            <img
              v-else
              :src="noImage"
              style="border-radius: 9px 0px 0px 9px"
              width="172px"
              alt=""
            />
          </div>
          <div text class="information-btn primary-light--bg text-14 font-500">
            ใช้ได้ {{ coupon.usage_limit }} ครั้ง
          </div>
        </div>
        <div class="condition">
          <div
            class="coupon-code white--text text-16 condition-head px-5 py-4 font-weight-bold primary-light--bg d-flex items-center"
            v-html="coupon.description"
          ></div>
        </div>
        <div class="px-5">
          <div class="information-group">
            <div class="information">
              <div class="primary--text mt-2 text-20 font-500">
                {{ coupon.name }}
              </div>
              <div
                class="text-14 mt-1"
                style="color: rgba(117, 117, 117, 1)"
                v-html="coupon.description"
              ></div>
              <v-divider class="my-4"></v-divider>
              <div class="details">
                <div class="details-info mt-2">
                  <v-row
                    class="text-12"
                    style="color: rgba(150, 149, 149, 1)"
                    no-gutters
                  >
                    <v-col cols="6">ประเภท:</v-col>
                    <v-col
                      class="text-14"
                      style="color: rgba(117, 117, 117, 1)"
                      cols="6"
                      >{{ coupon.type }}</v-col
                    >
                  </v-row>
                  <v-row
                    class="text-12"
                    style="color: rgba(150, 149, 149, 1)"
                    no-gutters
                  >
                    <v-col cols="6">วันหมดอายุ:</v-col>
                    <v-col
                      class="text-14"
                      style="color: rgba(117, 117, 117, 1)"
                      cols="6"
                      >{{
                        toDate(
                          coupon.expiration_mode === "duration"
                            ? +new Date() + coupon.expiration
                            : coupon.expiration
                        )
                      }}</v-col
                    >
                  </v-row>
                  <v-row
                    class="text-12"
                    style="color: rgba(150, 149, 149, 1)"
                    no-gutters
                  >
                    <v-col cols="6">จำนวนคูปองคงเหลือ:</v-col>
                    <v-col
                      class="text-14"
                      style="color: rgba(117, 117, 117, 1)"
                      cols="6"
                      >{{ coupon.remains }}</v-col
                    >
                  </v-row>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="condition">
          <div
            class="white--text text-16 condition-head px-5 py-4 font-weight-bold mt-5 primary-gradient--bg d-flex items-center"
          >
            เงื่อนไขการรับสิทธิ์
          </div>
          <div class="message pa-4" v-html="coupon.term_condition"></div>
        </div>
        <v-divider class="my-4"></v-divider>
        <div class="slide-group">
          <div class="primary--text text-center">
            <div class="text-16 font-500 primary--text">
              กรุณาเลื่อนไปทางขวาเพื่อเก็บคูปอง
            </div>
            <!-- <div class="text-12" style="color: rgba(150, 149, 149, 1)">
                    และโปรดแสดงหน้าใช้สิทธิบัตรสมาชิกนี้ต่อเจ้าหน้าที่ เท่านั้น
                  </div> -->
          </div>
        </div>

        <div class="mt-5 d-flex justify-center mb-9" v-if="active">
          <drag-verify
            :width="285"
            :height="50"
            text="เลื่อนไปทางขวา"
            success-text="สำเร็จ"
            text-size="14"
            handlerBg="#235D3A"
            background="#EAEAEA"
            progressBarBg="#34343434"
            :handlerIcon="arrowLeft"
            completedBg="#235D3A"
            :circle="true"
            @passcallback="$emit('submit')"
          ></drag-verify>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      noImage: require("@/assets/img/phyathai-logo-white.png"),
      arrowLeft: require("@/assets/icon/arrowleft.png"),
      coupon: null,
    };
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    giveawayCoupon: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    giveawayCoupon() {
      this.fetchCoupon();
    },
  },
  methods: {
    fetchCoupon() {
      this.coupon = this.giveawayCoupon;
      // console.log(this.coupon);
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(170, 170, 170, 0.5);
  border-radius: 20px;
}
.bg-color primary-gradient--bg {
  color: #ffffff;
}
.bg-icon {
  background: #fff;
}
.coupon-thumbnail {
  position: relative;
}
.information-btn {
  position: absolute;
  top: 20px;
  left: 0;
  border-radius: 0px 20px 20px 0px;
  width: 90px !important;
  height: 40px !important;
  color: white;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  z-index: 2;
  justify-content: center;
}
.coupon-img {
  object-fit: cover;
  width: 100%;
  height: 185px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    object-fit: cover;
  }
}
.coupon-code {
  margin-top: -8px;
}
</style>

<template>
  <div class="primary--text">
    <div class="card-container px-5">
      <div class="card-group pb-5 px-5 pt-2">
        <div class="pt-4">
          <div class="text-18 font-500">ประวัติการใช้คูปองและสิทธิพิเศษ</div>
          <div class="line-h-1 text-20 font-700">บัตร {{ titleByDomain }}</div>
        </div>
        <v-divider class="my-5"></v-divider>
        <div class="mb-6">
          <div
            class="card mb-2"
            v-for="(item, index) in historylog"
            :key="index"
          >
            <div class="px-5 py-2">
              <div class="font-700 text-14">{{ item.title }}</div>
              <div class="font-500 text-14">{{ item.description }}</div>
              <div class="expired text-10 mt-1">
                ใช้สิทธิ์เมื่อ : {{ toDate(item.time) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "history",
  components: {},
  props: {
    historylog: {
      type: Array,
      default: () => ({}),
    },
  },
  data: () => ({}),
  computed: {
    ...mapState("Layout", ["onDomain"]),

    titleByDomain() {
      if (this.onDomain === "be") {
        return "Be Phyathai 3 Family";
      } else if (this.onDomain === "prestige") {
        return "Long Term Prestige";
      } else if (this.onDomain === "all-you") {
        return "All You Can Check";
      } else {
        return "Mommy & Baby Club";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  background: #ffffff;
  border: 1px solid var(--v-primaryLight-base);
  box-shadow: 0px 4px 20px rgba(170, 170, 170, 0.5);
  border-radius: 10px;
}
.card-container {
  padding: 0px 1.8rem;
}
.card-group {
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 4px 20px rgba(170, 170, 170, 0.5);
}

.expired {
  color: #cecece;
}
</style>

<template>
  <div class="user-information mb-10">
    <div class="card-container px-5">
      <div v-if="showHeader" class="information-header">
        <div class="header-group pt-3 px-5">
          <div
            class="head-top d-flex justify-space-between"
            style="color: #235d3a"
          >
            <div class="head-title primary--text">
              <div class="font-700 text-20">
                {{ onDomain === "be" ? "My Family" : "My Baby" }}
              </div>
              <div class="text-16">
                {{ onDomain === "be" ? "Be Phyathai 3" : "Mommy & Baby Club" }}
              </div>
            </div>
            <!-- <div v-show="onDomain === 'be'" class="ref-code text-14">
              <span
                v-if="informations.host?.code === user.code"
                class="text-14"
                style="color: #757575"
                >Invite Code : {{ informations.host?.code }}
              </span>
              <span v-else>Family Code : {{ informations.host?.code }} </span>
            </div> -->
          </div>
          <div
            v-if="onDomain === 'be'"
            class="d-flex mt-1 align-end justify-space-between"
          >
            <img width="67px" height="62px" :src="familyLogo" />
            <div
              class="profile-group d-flex align-end pb-3"
              @click="showProfile = true"
            >
              <div
                v-show="informations.host?.code !== user.code"
                class="profile"
                style="width: 60px; height: 60px"
              >
                <img
                  :src="informations.host?.general_profile?.picture_url"
                  class="profile-picture"
                  width="100%"
                />
              </div>
              <div
                v-for="(profile, index) in informations.members"
                :key="index"
              >
                <div
                  class="profile"
                  :style="
                    informations.host?.code !== user.code
                      ? 'width: 50px; height: 50px'
                      : 'width: 60px; height: 60px'
                  "
                >
                  <img
                    :src="profile.general_profile?.picture_url"
                    class="profile-picture"
                    width="100%"
                  />
                </div>
              </div>
              <v-btn
                icon
                v-show="
                  informations.host?.code === user.code &&
                  informations?.members.length < 3
                "
                class="add-profile"
                :style="
                  informations.host?.code !== user.code
                    ? 'width: 50px; height: 50px'
                    : 'width: 60px; height: 60px'
                "
              >
                <img width="17px" :src="plus" />
              </v-btn>
            </div>
          </div>

          <div v-else class="d-flex mt-1 align-end justify-space-between">
            <img width="80px" height="65px" :src="mombabyLogo" />
            <div
              class="profile-group d-flex align-end pb-3"
              @click="showProfile = true"
            >
              <div v-for="(profile, index) in informations.babies" :key="index">
                <div class="profile" style="width: 60px; height: 60px">
                  <img
                    :src="profile.gender === 'male' ? babyMale : babyFemale"
                    class="profile-picture"
                    width="100%"
                  />
                </div>
              </div>
              <v-btn icon class="add-profile" style="width: 60px; height: 60px">
                <img width="17px" :src="plus" />
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <div
        class="card-group py-8 px-5"
        :style="[!showHeader && { 'border-radius': '20px' }]"
      >
        <div
          v-if="consent.content"
          class="information-detail"
          style="overflow-wrap: break-word"
          v-html="consent.content"
        ></div>
        <div v-else class="font-700 text-center text-16">ไม่พบเนื้อหา</div>
      </div>
    </div>
    <FamilyDialog
      v-if="showProfile"
      :active="showProfile"
      :informations="informations"
      @fetchInformations="fetchInformations"
      @close="showProfile = false"
    />
  </div>
</template>

<script>
import FamilyDialog from "@/components/dialog/FamilyDialog";

import { mapState } from "vuex";
export default {
  name: "user-information",
  components: {
    FamilyDialog,
  },
  props: {
    consent: {
      type: Object,
      default: () => ({}),
    },
    informations: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    familyLogo: require("@/assets/img/family logo.png"),
    mombabyLogo: require("@/assets/img/mom-baby logo.png"),
    babyMale: require("@/assets/img/baby_male.svg"),
    babyFemale: require("@/assets/img/baby_female.svg"),
    plus: require("@/assets/icon/plus.png"),

    showProfile: false,
  }),
  computed: {
    ...mapState("Layout", ["onDomain"]),
    ...mapState("Auth", ["user", "auth"]),
    showHeader() {
      if (this.onDomain === "be" || this.onDomain === "mom-baby") {
        return true;
      }
      return false;
    },
  },
  methods: {
    fetchInformations() {
      this.showProfile = false;
      this.$emit("fetchInformations");
    },
  },
};
</script>

<style lang="scss" scoped>
.card-container {
  padding: 0px 1.8rem;
}
.card-group {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(170, 170, 170, 0.5);
  border-radius: 0px 0px 20px 20px;
}

.information-header {
  background: var(--v-primaryInformation-base);
  border: 1px solid var(--v-primaryLight-base);
  box-shadow: 0px 4px 20px rgba(170, 170, 170, 0.5);
  border-radius: 20px 20px 0px 0px;
}
.profile {
  border: 2px solid var(--v-primaryLight-base);
  border-radius: 100%;
}
.add-profile {
  display: flex;
  align-items: center;
  justify-content: center;
  border: dashed 1.3px #757575;
  border-radius: 100%;
}

.profile-group {
  column-gap: 7px;
}

.profile-picture {
  border-radius: inherit;
}

.ref-code {
  padding: 10px;
  margin: auto 0px;
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid var(--v-primary-base);
  border-radius: 20px;
  white-space: nowrap;
  width: 190px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

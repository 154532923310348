<template>
  <v-row justify="center">
    <v-dialog v-model="active" persistent max-width="350">
      <v-card class="card">
        <v-card-title
          class="bg-color primary-gradient--bg d-flex justify-space-around pa-3"
        >
          <v-spacer></v-spacer>
          <v-toolbar-title class="text-20 text-center"
            >เพิ่มข้อมูลลูก</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn class="bg-icon" icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <div class="d-flex justify-center">
          <div>
            <div class="profile-form mt-5 font-500 text-14">
              <div class="col-12">
                <div>ชื่อจริง <span class="error--text">*</span></div>
                <ValidationProvider
                  name="firstName"
                  :rules="{ required: true, regex: /^[a-zA-Zก-๙]*$/ }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    class="mt-3 text-14"
                    v-model="profile.first_name"
                    outlined
                    rounded
                    dense
                    required
                    hide-details="auto"
                    placeholder="โปรดกรอกชื่อจริง"
                  ></v-text-field>
                  <span class="error--text">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="col-12">
                นามสกุล <span class="error--text">*</span>
                <ValidationProvider
                  name="lastName"
                  :rules="{ required: true, regex: /^[a-zA-Zก-๙]*$/ }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    class="mt-3 text-14"
                    v-model="profile.last_name"
                    outlined
                    rounded
                    dense
                    required
                    hide-details="auto"
                    placeholder="โปรดกรอกชื่อนามสกุล"
                  ></v-text-field>
                  <span class="error--text">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="col-12">
                เพศ <span class="error--text">*</span>
                <ValidationProvider
                  name="gender"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-select
                    class="mt-3 text-14"
                    v-model="profile.gender"
                    :items="genderOption"
                    item-value="value"
                    item-text="name"
                    placeholder="กรุณาเลือกเพศ"
                    data-vv-name="select"
                    required
                    rounded
                    outlined
                    dense
                    hide-details="auto"
                  ></v-select>
                  <span class="error--text">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="mt-5 d-flex justify-center mb-9">
              <v-btn
                text
                class="register-btn primary--bg btn text-16 py-5"
                @click="submit"
                >บันทึกข้อมูล</v-btn
              >
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  name: "add-baby-modal",
  data() {
    return {
      closeicon: require("@/assets/icon/closeicon.png"),
      genderOption: [
        { value: "male", name: "ชาย" },
        { value: "female", name: "หญิง" },
      ],
      profile: {
        first_name: "",
        last_name: "",
        gender: "",
      },
    };
  },
  props: {
    isInvalid: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    submit() {
      this.$emit("submit", this.profile);
      this.code = null;
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(170, 170, 170, 0.5);
  border-radius: 20px;
}
.bg-color {
  color: #ffffff;
}

.register-btn {
  color: white;
}

.bg-icon {
  background: #fff;
}
</style>

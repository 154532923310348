<template>
  <div class="coupon-menu d-flex justify-space-between">
    <div
      v-for="(option, index) in options"
      :key="index"
      class="coupon-bar primary--text d-flex align-center justify-center"
      :class="[
        {
          'onTab font-700': onTab === option,
        },
      ]"
      @click="handleClick(option)"
    >
      <span class="text-14"
        ><slot>{{ option }}</slot></span
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      onTab: this.options[0],
    };
  },
  props: {
    options: {
      type: Array,
    },
  },
  methods: {
    handleClick(tab) {
      this.$emit("selectTab", tab);
      this.onTab = tab;
    },
  },
};
</script>

<style lang="scss" scoped>
.coupon-menu {
  column-gap: 10px;
}
.coupon-bar {
  text-transform: capitalize;
  width: 100%;
  height: 2.7rem !important;
  background: white !important;
  box-shadow: none;
  font-weight: 500;
  border-radius: 10px 10px 0px 0px;
  &.onTab {
    background: #397d54 !important;
    color: white !important;
  }
}
</style>

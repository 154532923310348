<template>
  <div class="coupon">
    <v-text-field
      v-if="coupon.length > 0"
      class="search-input mt-4"
      label="Search"
      outlined
      single-line
      rounded
      dense
      v-model="search"
      append-icon="mdi-magnify"
    ></v-text-field>
    <div class="card-container pb-5" v-if="isAvalible">
      <div v-for="(itemCoupon, index) in coupon" :key="index">
        <div v-show="itemCoupon?.items?.length > 0">
          <div class="section-title font-500">
            {{ itemCoupon.section }}
          </div>
          <v-divider class="mt-2 mb-5"></v-divider>
        </div>

        <div
          class="card-group mb-2"
          v-for="(item, index) in itemCoupon.items"
          :key="-index"
          @click="handleClick(item)"
        >
          <!-- {{ item }} -->
          <v-row no-gutters>
            <v-col cols="4">
              <div class="coupon-img primary-gradient--bg">
                <img
                  v-if="item.thumbnail_image_url"
                  :src="item.thumbnail_image_url"
                  width="110px"
                  height="110px"
                  alt=""
                />
                <img v-else width="82px" :src="noImage" alt="" />
              </div>
            </v-col>
            <v-col cols="8" class="coupon-info px-4 py-2">
              <v-row no-gutters>
                <v-col cols="12">
                  <div class="coupon-title primary--text text-14 font-500">
                    {{ item.name }}
                  </div>
                </v-col>
                <v-col cols="12">
                  <div
                    class="description text-12 font-400"
                    v-html="item.description"
                  ></div>
                </v-col>
                <v-col cols="12">
                  <div v-if="item.expiration > 0" class="expired text-10">
                    หมดอายุ :
                    {{
                      toDate(
                        item.expiration_mode === "duration"
                          ? +new Date() + item.expiration
                          : item.expiration
                      )
                    }}
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
    <div v-else class="text-center font-700 mt-10 pb-10">ไม่มีคูปอง</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      noImage: require("@/assets/img/phyathai-logo-white.png"),
      search: "",
    };
  },
  props: {
    onTab: {
      type: String,
      default: "",
    },
    coupons: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    coupon() {
      const filterCoupon = this.coupons.filter(
        (e) => e.category === this.onTab
      );
      if (filterCoupon.length > 0) {
        const coupon = [
          ...new Set(filterCoupon[0].items.map((e) => e.section)),
        ].map((s) => {
          return {
            section: s,
            items: filterCoupon[0].items.filter(
              (c) =>
                c.section === s &&
                c.name
                  .toLocaleLowerCase()
                  .includes(this.search.toLocaleLowerCase())
            ),
          };
        });
        // console.log(coupon);
        return coupon;
      } else {
        return [];
      }
    },
    isAvalible() {
      if (this.coupon.length < 1) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    handleClick(coupon) {
      this.$emit("selectCoupon", coupon);
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  background: #ffffff;
  border: 1px solid #73c088;
  box-shadow: 0px 4px 20px rgba(170, 170, 170, 0.5);
  border-radius: 10px;
}
.section-title {
  color: #235d3a;
}

.card-group {
  border-radius: 10px;
  border: 1px solid var(--v-primaryLight-base) !important;
  box-shadow: 0px 4px 20px rgba(170, 170, 170, 0.5);
}
.coupon-img {
  border-radius: 8px 0px 0px 8px;
  width: 110px;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    border-radius: 8px 0px 0px 8px;
    object-fit: cover;
  }
}
.coupon-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.description {
  color: #757575;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.expired {
  color: #cecece;
}
</style>

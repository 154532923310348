<template>
  <div class="phyathai-menu d-flex align-center justify-center">
    <div
      v-for="(item, index) in options"
      :key="index"
      class="menu-box primary--text d-flex align-center justify-center"
      :class="[
        {
          'onMenu primary-gradient--bg font-700': onMenu === index,
        },
      ]"
      @click="selectMenu(index)"
    >
      <div class="text-center">
        <img :src="item.icon" width="40" alt="" srcset="" />
        <div class="type-title text-12">{{ item.title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      onMenu: 0,
    };
  },
  props: {
    options: {
      type: Array,
    },
  },
  watch: {
    onDomain() {
      this.onMenu = 0;
    },
  },
  computed: {
    ...mapState("Layout", ["onDomain"]),

    menu() {
      // if (this.onDomain === "prestige") {
      //   return this.options.filter((e) => e.title !== "Coupon");
      // } else if (this.onDomain === "all-you") {
      //   return this.options.filter((e) => e.title !== "Coupon");
      // } else if (this.onDomain === "mom-baby") {
      //   return this.options.filter((e) => e.title !== "Coupon");
      // } else {
      //   return this.options;
      // }
      if (this.onDomain === "be") {
        return this.options;
      } else {
        return this.options.filter((e) => e.title !== "Be Coupon");
      }
    },
  },
  methods: {
    selectMenu(index) {
      if (this.onMenu === index) return;
      this.$emit("onSelectMenu", index);
      this.onMenu = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.phyathai-menu {
  column-gap: 0.5rem;
}
.menu-box {
  text-transform: capitalize;
  width: 82px;
  height: 82px;
  font-weight: 500;
  box-shadow: 0px 4px 20px rgba(170, 170, 170, 0.5);
  border-radius: 10px;

  img {
    filter: invert(50%) sepia(25%) saturate(940%) hue-rotate(90deg)
      brightness(50%) contrast(45%);
  }

  &.onMenu {
    color: white !important;
    img {
      filter: invert(0);
    }
  }
}
</style>

<template>
  <div class="main-home pb-10">
    <PhyathaiCard
      @changeCard="selectDomain"
      @renewCard="showConfirmModal = true"
      @slideCard="confirmUseCard"
    />
    <MenuBar class="mt-4" :options="menuOptions" @onSelectMenu="selectMenu" />
    <div v-if="show === 'Be Coupon'" class="card-container px-5">
      <CouponBar class="mt-5" :options="barOptions" @selectTab="selectTab" />
      <div class="coupon-card-group px-5">
        <div class="coupons mb-6">
          <div class="mb-2">
            <CouponCard
              :onTab="onTab"
              :coupons="coupons"
              @selectCoupon="selectCoupon"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="show === 'My Coupon'" class="card-container px-5 mt-5">
      <div class="my-coupon-card-group pb-5 px-5">
        <div class="coupons mb-6">
          <div class="mb-2">
            <MyCoupon :coupons="myCoupons" @selectCoupon="selectMyCoupon" />
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="show === 'Information'" class="mt-5">
      <UserInformation
        :consent="consent"
        :informations="informations"
        @fetchInformations="fetchInformations"
      />
    </div>
    <div v-else-if="show === 'History'" class="mt-5">
      <History :historylog="history" />
    </div>

    <ConfirmModal
      :active="showConfirmModal"
      :message="'คุณต้องต่ออายุบัตรนี้ใช่หรือไม่ ?'"
      @submit="(showRenewCouponModal = true), (showConfirmModal = false)"
      @close="showConfirmModal = false"
    />
    <ConfirmUseCardModal
      :active="showConfirmUseCardModal"
      @submit="useCard"
      @close="showConfirmUseCardModal = false"
    />

    <RenewCouponModal
      :active="showRenewCouponModal"
      :isInvalid="isInvalidRenewCode"
      @close="showRenewCouponModal = false"
      @submit="renewCard"
    />

    <CollectCouponModal
      :active="showCollectCoupon"
      :giveawayCoupon="selectedCoupon"
      @submit="collectCoupon"
      @close="showCollectCoupon = false"
    />
    <SuccessModal
      :active="showSuccessModal"
      :message="successMessage"
      @submit="showSuccessModal = false"
    />
    <!-- <UseCouponDialog
      :active="showUseCoupon"
      :details="selectedMyCoupon"
      @submit="useCoupon"
      @close="showUseCoupon = false"
    /> -->
    <ErrorModal
      :active="showError"
      :status="errorStatus"
      @close="showError = false"
    />
    <UseCouponDialog
      :active="showUseCoupon"
      :coupon="selectedMyCoupon"
      @submit="useCoupon"
      @close="showUseCoupon = false"
    />
  </div>
</template>

<script>
import PhyathaiCard from "@/components/PhyathaiCard";

import CouponBar from "@/components/coupon/CouponBar";
import CouponCard from "@/components/coupon/CouponCard";
import MenuBar from "@/components/coupon/MenuBar";
import MyCoupon from "@/components/coupon/MyCoupon";

import UserInformation from "@/components/menu/UserInformation";
import History from "@/components/menu/History";

import ConfirmUseCardModal from "@/components/modal/ConfirmUseCardModal";

// import CollectCouponDialog from "@/components/dialog/CollectCoupon";

import RenewCouponModal from "@/components/modal/RenewCouponModal";
import ConfirmModal from "@/components/modal/ConfirmModal";
import CollectCouponModal from "@/components/modal/CollectCouponModal";
import SuccessModal from "@/components/modal/SuccessModal";
import ErrorModal from "@/components/modal/ErrorModal";

import UseCouponDialog from "@/components/dialog/UseCouponDialog";

import { mapActions, mapState } from "vuex";

export default {
  name: "Home",
  components: {
    PhyathaiCard,
    CouponBar,
    CouponCard,

    MenuBar,
    MyCoupon,
    UserInformation,
    History,

    // CollectCouponDialog,

    ConfirmModal,
    ConfirmUseCardModal,
    RenewCouponModal,
    CollectCouponModal,
    SuccessModal,
    ErrorModal,

    UseCouponDialog,
  },
  data: () => ({
    show: "Be Coupon",
    onTab: "Be Coupon",
    selectedCoupon: {},
    showConfirmUseCardModal: false,
    showCollectCoupon: false,
    showSuccessModal: false,
    showConfirmModal: false,
    showRenewCouponModal: false,

    isInvalidRenewCode: false,

    selectedMyCoupon: {},
    showUseCoupon: false,

    showError: false,
    errorStatus: null,

    coupons: [],
    myCoupons: [],
    history: [],

    onCoupon: [],

    consent: {},

    successMessage: {},
    onMenu: 0,

    informations: {},

    phyathaiMenu: [
      {
        icon: require("@/assets/icon/beCoupon.svg"),
        title: "Be Coupon",
      },
      {
        icon: require("@/assets/icon/myCoupon.svg"),
        title: "My Coupon",
      },
      {
        icon: require("@/assets/icon/information.svg"),
        title: "Information",
      },
      {
        icon: require("@/assets/icon/history.svg"),
        title: "History",
      },
    ],
    barOptions: ["Be Coupon", "Be Bonus", "Be Gift"],
  }),

  computed: {
    ...mapState("Request", ["requestApi"]),
    ...mapState("Auth", ["user"]),
    ...mapState("Layout", ["onDomain"]),
    menuOptions() {
      if (this.onDomain === "be") {
        return this.phyathaiMenu;
      } else {
        return this.phyathaiMenu.filter((e) => e.title !== "Be Coupon");
      }
    },
  },
  watch: {
    onDomain() {
      this.setupData();
    },
  },
  methods: {
    ...mapActions({
      setonDomain: "Layout/setonDomain",
    }),
    confirmUseCard() {
      this.showConfirmUseCardModal = true;
    },
    async useCard() {
      try {
        this.$wait.start("app loading");
        this.showConfirmUseCardModal = false;
        await this.requestApi.membershipCard.useMembershipCard({
          domain: this.onDomain,
        });
        this.successMessage = {
          title: "ยืนยันการใช้สิทธิ์เรียบร้อย",
          description: "ระบบได้ทำการบันทึกข้อมูลของท่านแล้ว",
        };
        this.showSuccessModal = true;
      } catch (err) {
        alert("ไม่สำเสร็จ", err);
      } finally {
        this.$wait.end("app loading");
      }
    },
    async renewCard(code) {
      try {
        this.showRenewCouponModal = false;
        this.$wait.start("app loading");
        await this.requestApi.membership.verifyMembershipRenewCode({
          domain: this.onDomain,
          code,
        });
        await this.requestApi.membership.renewMembership({
          domain: this.onDomain,
          requestBody: {
            code,
          },
        });
        this.showRenewCouponModal = false;
        this.isInvalidRenewCode = false;
      } catch (err) {
        console.log(err);
        this.showRenewCouponModal = true;
        this.isInvalidRenewCode = true;
      } finally {
        this.$wait.end("app loading");
      }
    },
    selectDomain(card) {
      this.setonDomain(card);
    },
    selectTab(tab) {
      this.onTab = tab;
    },
    selectCoupon(coupon) {
      this.selectedCoupon = coupon;
      this.showCollectCoupon = true;
    },
    selectMyCoupon(coupon) {
      this.selectedMyCoupon = coupon;
      this.showUseCoupon = true;
    },
    selectMenu(index) {
      if (this.menuOptions[index].title === "Be Coupon") {
        this.fetchCoupon();
      } else if (this.menuOptions[index].title === "My Coupon") {
        this.fetchMyCoupon();
      } else if (this.menuOptions[index].title === "Information") {
        this.readConsent();
        this.fetchInformations();
      } else if (this.menuOptions[index].title === "History") {
        this.fetchHistory();
      }
      this.onTab = "Be Coupon";
      this.show = this.menuOptions[index].title;
    },
    async collectCoupon() {
      this.showCollectCoupon = false;
      try {
        this.$wait.start("app loading");
        await this.requestApi.membershipCouponGiveaway.claimMembershipCouponGiveaway(
          {
            domain: this.onDomain,
            couponId: this.selectedCoupon.id,
          }
        );
        await this.fetchCoupon();
        this.successMessage = {
          title: "จัดเก็บคูปองเรียบร้อย",
          description: "สามารถตรวจสอบคูปองได้ที่ <br/>“My Coupon”",
        };
        this.showSuccessModal = true;
      } catch (err) {
        console.log(err);

        this.showError = true;
        this.errorStatus = "collectCouponFail";
      } finally {
        this.$wait.end("app loading");
      }
    },
    async useCoupon() {
      try {
        this.showUseCoupon = false;
        this.$wait.start("app loading");
        await this.requestApi.membershipCoupon.useMembershipCoupon({
          domain: this.onDomain,
          membershipCouponId: this.selectedMyCoupon.id,
        });
        await this.fetchMyCoupon();
        this.successMessage = {
          title: "ยืนยันการใช้สิทธิ์เรียบร้อย",
          description: "คูปองของท่านได้ถูกใช้งานแล้ว",
        };
        this.showSuccessModal = true;
      } catch (err) {
        console.log(err);
      } finally {
        this.$wait.end("app loading");
      }
    },
    setupData() {
      if (this.onDomain !== "be") {
        this.show = "My Coupon";
        this.fetchMyCoupon();
      } else {
        this.show = "Be Coupon";
        this.fetchCoupon();
      }
    },
    async readConsent() {
      try {
        this.$wait.start("app loading");
        const consents = await this.requestApi.consent.getConsent({
          id: this.onDomain,
        });
        this.consent = consents;
      } catch (err) {
        console.log(err);
      } finally {
        this.$wait.end("app loading");
      }
    },
    async fetchHistory() {
      try {
        this.$wait.start("app loading");
        const history =
          await this.requestApi.membershipHistory.getMembershipHistory({
            domain: this.onDomain,
          });
        this.history = history;
      } catch (err) {
        console.log(err);
      } finally {
        this.$wait.end("app loading");
      }
    },
    async fetchCoupon() {
      try {
        this.$wait.start("app loading");
        const couponGiveAway =
          await this.requestApi.membershipCouponGiveaway.getMembershipCouponGiveaways(
            { domain: this.onDomain }
          );
        this.coupons = couponGiveAway;
      } catch (err) {
        console.log(err);
      } finally {
        this.$wait.end("app loading");
      }
    },
    async fetchMyCoupon() {
      try {
        this.$wait.start("app loading");
        this.myCoupons = [];
        const myCoupons =
          await this.requestApi.membershipCoupon.getMembershipCoupons({
            domain: this.onDomain,
          });
        myCoupons.map((i) =>
          i.items.map((c) => {
            this.myCoupons.push(c);
          })
        );
      } catch (err) {
        console.log(err);
      } finally {
        this.$wait.end("app loading");
      }
    },

    async fetchInformations() {
      try {
        this.$wait.start("app loading");
        if (this.onDomain === "be") {
          this.informations =
            await this.requestApi.membershipHost.getMembershipHost({
              domain: this.onDomain,
            });
          // this.informations.members = [
          //   {
          //     name: "jeff",
          //   },
          //   {
          //     name: "jeff",
          //   },
          // ];
        } else {
          this.informations =
            await this.requestApi.membershipBaby.getMembershipBaby({
              domain: this.onDomain,
            });
          // this.informations.babies = [
          //   {
          //     name: "jeff",
          //   },
          // ];
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.$wait.end("app loading");
      }
    },
  },
  created() {
    this.setupData();
  },
};
</script>

<style lang="scss" scoped>
.card-container {
  padding: 0px 1.8rem;
}
.coupon-card-group {
  background: #ffffff;
  border-top: 5px solid #397d54;
  border-radius: 0 0 20px 20px;
  box-shadow: 0px 4px 20px rgba(170, 170, 170, 0.5);
}
.my-coupon-card-group {
  min-height: 100%;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 4px 20px rgba(170, 170, 170, 0.5);
}

.coupon-type {
  color: #235d3a;
}
</style>
